<template>
  <div v-if="page_data" class="mb-3">
    <h2 class="m-0 page-title">
      {{ $t("orders.order_&_account_information") }}
    </h2>
    <hr />
    <b-row>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">{{ $t("orders.order_information") }}</h3>
          <ul>
            <li>
              <strong>{{ $t("orders.order_number") }}</strong>
              {{ page_data.order_code }}
            </li>
            <li>
              <strong>{{ $t("orders.order_date") }}</strong>
              {{ $helpers.dateTimeInFormat(page_data.created_at) }}
            </li>
            <li>
              <strong>{{ $t("orders.customer_name") }}</strong>
              {{
                page_data.customer.first_name +
                " " +
                page_data.customer.last_name
              }}
            </li>
            <li>
              <strong>{{ $t("orders.order_status") }}</strong>
              <b-form class="form-status">
                <b-form-select
                  :options="status_order"
                  :disabled="!permission.orders.update"
                  v-model="order_state"
                  @input="handleChangeStatus"
                  text-field="text"
                  value-field="value"
                >
                </b-form-select>
                <b-badge :variant="order_state === 4 ? 'success' : 'warning'"
                  >{{
                    status_order.filter((item) => item.value === order_state)[0]
                      .text
                  }}
                </b-badge>
              </b-form>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">
            {{ $t("orders.delivery_boy_information") }}
          </h3>
          <ul v-if="page_data.delivery_boy">
            <li>
              <strong>{{ $t("orders.phone_number") }}</strong>
              {{
                page_data.delivery_boy
                  ? page_data.delivery_boy.user.phone[0].phone
                  : $t("g.no_number")
              }}
            </li>
            <li>
              <strong>{{ $t("orders.delivery_boy") }}</strong>
              {{
                page_data.delivery_boy
                  ? page_data.delivery_boy.user.first_name +
                    " " +
                    page_data.delivery_boy.user.last_name
                  : $t("orders.delivery_boy_has_not_been_added_yet")
              }}
            </li>
          </ul>
          <strong v-else>{{
            $t("orders.delivery_boy_has_not_been_added_yet")
          }}</strong>
        </div>
      </b-col>
      <overlay-component :is-loading="busy" />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormSelect, BBadge } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";

export default {
  name: "OrdersInformation",
  components: {
    OverlayComponent,
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BBadge,
  },

  props: ["page_data"],
  data() {
    return {
      order_state: null,
      status_order: [
        {
          text: this.$t("orders.edit_order_status"),
          value: null,
          disabled: true,
        },
      ],
      busy: false,
    };
  },
  computed: {
    permission() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  methods: {
    handleChangeStatus() {
      this.busy = true;
      const formData = new FormData();
      formData.append("status_id", this.order_state);
      this.$http
        .post(`vendor/orders/${this.page_data.id}?_method=put`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.busy = false;
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
          }
        })
        .catch((err) => {
          this.busy = false;

          this.$helpers.handleError(err);
        });
    },
    handelOrderStatus(status) {
      if (Array.isArray(status) && status.length > 0) {
        status.forEach(({ name, id, active_date }) => {
          if (active_date) {
            this.order_state = id;
          }
          this.status_order.push({ disabled: false, text: name, value: id });
        });
      }
    },
  },
  watch: {
    "page_data.status": {
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.handelOrderStatus(newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "./_OrdersInformation";
</style>
