<template>
  <div v-if="page_data" class="mb-3">
    <h2 class="m-0 page-title">{{ $t("orders.items_orders") }}</h2>
    <hr />
    <vue-good-table
      :columns="tableHeader"
      :rows="page_data.order_items"
      styleClass="table-custom-style vgt-table "
      :pagination-options="{
        enabled: false,
      }"
    >
      <!-- Column: Name -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label === 'products'" class="text-nowrap">
          {{ $t("orders.products") }}
        </span>
        <span
          v-else-if="props.column.label === 'unit_price'"
          class="text-nowrap"
        >
          {{ $t("orders.unit_price") }}
        </span>
        <span v-else-if="props.column.label === 'quantity'" class="text-nowrap">
          {{ $t("orders.quantity") }}
        </span>
        <span v-else-if="props.column.label === 'total'" class="text-nowrap">
          {{ $t("orders.total") }}
        </span>
        <span v-else>
          {{ $t(`orders.${props.column.label}`) }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'products'">
            {{ props.row.product_name }}
          </span>
          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'unit_price'"
          >
            {{ props.row.product_price }}
          </span>

          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'quantity'"
          >
            {{ props.row.product_quantity }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'total'"
          >
            {{ props.row.total_price }} SAR
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
      </template>

      <div slot="emptystate">{{ $t("g.no_data") }}</div>
    </vue-good-table>
  </div>
</template>

<script>
import { BRow, BCol, BPagination } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "OrderItems",
  components: {
    BRow,
    BCol,
    BPagination,
    VueGoodTable,
  },
  props: ["page_data"],
  data() {
    return {
      tableHeader: [
        {
          label: "products",
          field: "products",
        },
        {
          label: "unit_price",
          field: "unit_price",
        },
        {
          label: "quantity",
          field: "quantity",
        },
        {
          label: "total",
          field: "total",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./_ItemsOrder.scss";
</style>
