<template>
  <div v-if="page_data" class="mb-3 order-address">
    <h2 class="m-0 page-title">{{ $t("orders.address_information") }}</h2>
    <hr />
    <b-row>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">
            {{ $t("orders.delivery_boy_information") }}
          </h3>
          <ul>
            <li>
              <strong>{{ $t("orders.phone_number") }}</strong>
              {{ page_data }}
            </li>
            <li>
              <strong>{{ $t("orders.delivery_boy") }}</strong>
              <!-- {{ page_data.phone }} -->
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "AddressOrder",
  components: {
    BRow,
    BCol,
  },
  props: ["page_data"],
};
</script>

<style lang="scss">
@import "./_Address.scss";
</style>
