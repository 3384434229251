<template>
  <div v-if="page_data">
    <b-row>
      <b-col offset-md="9" md="3">
        <div class="info total-info">
          <ul>
            <hr />
            <li>
              {{ page_data.sub_total }} SAR
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "OrderSubtotal",
  components: {
    BRow,
    BCol,
  },
  props: ["page_data"],
};
</script>

<style lang="scss">
@import "./_OrderSubtotal";
</style>
